const initState = {
  guestsList: [],
  page: 1,
  limit: 10,
  count: 0,
};

const GuestReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_GUEST":
      return {
        ...state,
        guestsList: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    case "RESET_STATE":
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default GuestReducer;
