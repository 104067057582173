const initState = {
  extrasList: [],
  page: 1,
  limit: 10,
  count: 0,
};
export const Extras = (state = initState, action) => {
  switch (action.type) {
    case "SET_EXTRA": {
      return {
        ...state,
        extrasList: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    }

    default: {
      return state;
    }
  }
};
