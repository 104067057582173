// import external modules
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// import internal(own) modules
import * as serviceWorker from './serviceWorker';
import { store } from './redux/storeConfig/store';
import ReduxToastr from 'react-redux-toastr';
import Spinner from './components/common/Spinner';
import { auth } from './api/auth';
import history from './app/history';
import withDragDropContext from './views/booking/DragAndDropContext';
import './index.scss';

const LazyApp = lazy(() => import('./app/AppRouter'));

const jsx = (
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <LazyApp />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Suspense>
  </Provider>
);
ReactDOM.render(jsx, document.getElementById('root'));

// let hasRendered = false;

const renderApp = (user = '') => {
  ReactDOM.render(jsx, document.getElementById('root'));
};

serviceWorker.register();

const renderLogin = () => {
  renderApp();
};
const tokenCheck = async () => {
  let response = await auth(`/admin/check-user-token`, {}, 'get');

  if (response && response.status !== 200) {
    history.push('/');
    store.dispatch({
      type: 'SET_LOGIN_FLAG',
      payload: { data: false },
    });
    store.dispatch({
      type: 'CHANGE_ROLE',
      payload: { data: '' },
    });
    store.dispatch({ type: 'LOGOUT_WITH_JWT', payload: {} });
  }
};
try {
  let user = localStorage.getItem('userPitka');

  if (user) {
    user = JSON.parse(user);

    store.dispatch({
      type: 'LOGIN_WITH_JWT',
      payload: { loggedInUser: user, loggedInWith: 'jwt' },
    });
    store.dispatch({
      type: 'SET_LOGIN_FLAG',
      payload: { data: true },
    });
    switch (user.role) {
      case 'admin':
        store.dispatch({
          type: 'CHANGE_ROLE',
          payload: { data: 'A' },
        });
        break;
      case 'super_admin':
        store.dispatch({
          type: 'CHANGE_ROLE',
          payload: { data: 'A' },
        });
        break;
      default:
        store.dispatch({
          type: 'CHANGE_ROLE',
          payload: { data: 'S' },
        });
    }
    tokenCheck();
  } else {
    renderLogin();
  }
} catch (e) {
  renderLogin();
}
