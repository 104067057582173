import { combineReducers } from "redux";
import { Menu } from "./menuReducer";
import { Careers } from "./careersReducer";
import { Store } from "./storeReducer";
import { Pages } from "./pageReducer";
import { Background } from "./backgroundReducer";
import { Extras } from "./extraReducer";
import { Amenities } from "./amenityReducer";
import { Content } from "./contentReducer";
const authReducers = combineReducers({
  Menu,
  Careers,
  Store,
  Pages,
  Background,
  Extras,
  Amenities,
  Content,
});

export default authReducers;
