import React from "react";
import { ClipLoader } from "react-spinners";

class SpinnerComponent extends React.Component {
  state = {
    loading: true,
  };

  render() {
    return (
      <div className="sweet-loading">
        <div className="custom-loading">
          <ClipLoader
            className="clip-loader"
            sizeUnit={"px"}
            size={60}
            color={"#eb8b3d"}
            loading={true}
          />
        </div>
      </div>
    );
  }
}

export default SpinnerComponent;
