// import external modules
import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import AdminDashboardReducer from "./adminDashboard/adminDashboardReducer";
import authReducers from "./auth/index";
import auditTrailReducer from "./auditTrail/auditTrailReducer";
import bookingReducer from "./booking/bookingReducer";
import companyUsersReducer from "./companyUsers/companyUsersReducer";
import cmsReducer from "./b2cCms/index";
import facilityReducer from "./facility/facilityReducer";
import imagesReducer from "./images/imagesReducer";
import guestReducer from "./guest/guestReducer";
import mailerReducer from "./mailer/mailerReducer";
import reviewReducer from "./review/reviewReducer";
import transactionReducer from "./transaction/transactionReducer";
const rootReducer = combineReducers({
  adminDashboard: AdminDashboardReducer,
  auth: authReducers,
  auditTrail: auditTrailReducer,
  booking: bookingReducer,
  cms: cmsReducer,
  companyUsers: companyUsersReducer,
  facility: facilityReducer,
  guest: guestReducer,
  images: imagesReducer,
  mailer: mailerReducer,
  review: reviewReducer,
  toastr: toastrReducer, // <- Mounted at toastr.
  transactions: transactionReducer,
});

export default rootReducer;
