const initState = {
  bookingsList: [],
  page: 1,
  limit: 10,
  count: 0,
  cartCount: 0,
};

const BookingReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_BOOKING':
      return {
        ...state,
        bookingsList: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    case 'SET_CART_COUNT':
      return {
        ...state,
        cartCount: action.payload.data,
      };
    case 'RESET_STATE':
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default BookingReducer;
