const initState = {
  mailerList: [],
  page: 1,
  limit: 10,
  count: 0,
};

const MailerReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_MAILER":
      return {
        ...state,
        mailerList: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    case "RESET_STATE":
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default MailerReducer;
