const initState = {
  menuList: [],
  menu: {},
  page: 1,
  limit: 10,
  count: 0,
};
export const Menu = (state = initState, action) => {
  switch (action.type) {
    case "SET_MENU": {
      return {
        ...state,
        menuList: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    }

    default: {
      return state;
    }
  }
};
