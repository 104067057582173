const initState = {
    contentList: [],
    page: 1,
    limit: 10,
    count: 0,
    isMailer:true,
  };
  export const Content = (state = initState, action) => {
    switch (action.type) {
      case "SET_CONTENT": {
        return {
          ...state,
          contentList: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count,
          isMailer:action.payload.isMailer,
        };
      }
  
      default: {
        return state;
      }
    }
  };